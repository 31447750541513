.product-all-div {
  max-width: 100vw;
  /* border: 2px solid blue; */
  box-sizing: border-box;
}

.products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* box-sizing: border-box; */
  align-items: center;
  margin-top: 50px;
  gap: 15px;
}

.product-list {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  border-bottom: 2px solid #e9e9e9;
}

.product-name {
  color: var(--foundation-grey-light-active, #bbb);
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 15px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

@media (max-width: 450px) {
  .product-name {
    font-size: 20px;
  }
}

.products-all-button {
  border-radius: 40px;
  background: var(--foundation-orange-normal, #f26957);
  color: #fff;
  text-align: center;
  font-family: Archivo;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 220px;
  height: 45px;
  border: 2px solid #f26957;
  margin: 15px auto;
  display: flex;
  align-items: center;
  margin-top: 35px;
  cursor: pointer;
  justify-content: center;
}
