.privacy {
  padding: 50px;
  padding-top: 25px;
}

.privacy-name {
  font-size: 35px;
  line-height: 40px;
  font-weight: 700;
  color: #000;
  font-family: "Archivo", sans-serif;
}

.privacy-text {
  font-size: 18px;
  line-height: 26px;
  max-width: 800px;
  letter-spacing: 0.2px;
  color: #000;
  font-family: "Archivo", sans-serif;
}

h5 {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 0;
  color: #000;
  font-family: "Archivo", sans-serif;
}

.privacy-item {
  margin: 10px 0;
  max-width: 800px;
  font-size: 18px;
  line-height: 26px;
  max-width: 800px;
  letter-spacing: 0.2px;
}

.privacy-list {
  list-style: circle !important;
}

@media (max-width: 450px) {
  .privacy {
    padding: 10px;
  }
  .privacy-name {
    font-size: 25px;
    line-height: 35px;
  }
  .privacy-text {
    font-size: 16px;
  }
  .privacy-item {
    font-size: 16px;
  }
}
