.register-name {
  color: var(--foundation-grey-normal, #232323);
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.register-form label {
  display: flex;
  flex-direction: column;
  color: #666;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0;
}

.register-form {
  padding: 0 60px;
}

.register-input {
  width: 380px;
  margin-top: 10px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  padding: 8px 20px;
  outline: none;
  height: 50px;
}

.register-label {
  display: flex;
}

.register-form p {
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 12px;
  width: 270px;
}

.form-button {
  border-radius: 40px;
  background: var(--foundation-orange-normal, #F26957);
  color: #fff;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 380px;
  height: 45px;
  border: 2px solid #f26957;
  margin-top: 50px;
  cursor: pointer;
}
