.product-modal-overlay,
.product-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.product-modal-overlay {
  background: rgba(0, 0, 0, 0.25);
}

.product-modal-wrapper {
  padding: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FCFCFD;
  z-index: 110;
  border-radius: 15px;
}

.product-modal-wrapper>img {
  height: 264px;
  width: 100%;
  object-fit: cover;
}

.product-modal-wrapper>h4 {
  margin-bottom: 32px;
  color: #23262F;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Archivo", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
}

.product-modal-wrapper>p {
  margin: 0;
  color: #87898E;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Archivo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.product-modal-wrapper>button {
  width: 100%;
  max-width: 482px;
  padding: 20px 10px;
  border-radius: 50px;
  background: var(--foundation-orange-normal, #F26957);
  border: none;
  cursor: pointer;
  color: var(--color-light-light, #FCFCFD);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 24px;
}