.blog {
  padding: 60px 0;
}

.blog-img-box {
  max-width: 403px;
  height: 176px;
  border-radius: 10px;
}

.blog-img {
  width: 380px;
  border-radius: 10px;
  height: 146px;
  object-fit: cover;
}

.blog-name {
  color: #000;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-bottom: 1px solid #e9e9e9;
}

.blog-item {
  border-radius: 20px;
  background: #f1f1f1;
  width: 400px;
  height: 312px;
  padding: 12px;
  margin-top: 20px;
}

.blog-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.blog-names {
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-text {
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  margin-bottom: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-time {
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1240px) {
  .blog-list {
    justify-content: space-evenly;
  }
}


@media (max-width:450px) {
  
  .blog-item{
    width: 100%;
  }
  .blog-img{
    width: 100%;
  }
}