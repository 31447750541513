.footer {
  width: 100vw;
  padding: 10px 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.footer-flex {
  background-image: url("../../assets/img/Futter.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 32px;
  min-height: 401px;
  padding: 15px 0;
}

.footer-link {
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6000000238418579;
}

.footer-list {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-img {
  margin-top: 36px;
  margin-left: 40px;
}

.footer-items {
  margin: 15px 0;
}

.footer-left {
  max-width: 450px;
  display: flex;
  gap: 0 60px;
}

.footer-button {
  border-radius: 30px;
  background: var(--foundation-orange-normal, #f26957);
  width: 280px;
  height: 50px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer label {
  display: flex;
  align-items: center;
  max-width: 470px;
  height: 58px;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: var(--foundation-grey-normal, #232323);
}

.footer-input {
  padding: 10px;
  display: flex;
  width: 400px;
  outline: none;
  background-color: transparent;
  color: #fff;
  border: none;
}

.footer-input::placeholder {
  color: var(--foundation-grey-light, #e9e9e9);
  font-family: "Archivo", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer-name {
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.footer-text {
  color: var(--foundation-grey-light, #e9e9e9);
  font-family: "Archivo", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  width: 320px;
}

.footer-right {
  box-sizing: border-box;
  padding: 15px 18px;
}

.footer-bottom,
.footer-bottomlast {
  gap: 18px;
  margin-left: 46px;
  margin-top: 40px;
}

.footer-bottom {
  display: none;
}

.footer-bottomlast {
  display: flex;
}

.footer-texts {
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  opacity: 0.6000000238418579;
  margin-left: 46px;
  margin-top: 30px;
}

@media (max-width: 755px) {
  .footer-list {
    flex-direction: column;
  }

  .footer-bottom {
    display: flex;
  }

  .footer-bottomlast {
    display: none;
  }
  .footer-link {
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .footer-left {
    gap: 0 20px;
  }

  .footer {
    margin-bottom: 100px;
  }

  .footer-name {
    font-size: 20px;
  }

  .footer .container {
    padding: 0 8px;
  }
}
