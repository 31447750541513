.profile-img {
  width: 154px;
  height: 154px;
  flex-shrink: 0;
  border-radius: 154px;
  border: 4px solid #fff;
  background: lightgray 0px -18.5px / 100% 150% no-repeat, #d9d9d9;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}

.product-edit-input {
  display: flex;
  align-items: center;
  padding: 0 8px;
  color: #000;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.product-edit-input::after {
  content: "+";
  position: absolute;
  left: 8.3%;
}

.profileBackground {
  width: 100%;
  background-image: url("../../assets/img/Banner\ \(2\).png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 327px;
  border-radius: 30px;
}

.profileBackground-mobile {
  background-image: url("../../assets/banner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 30px;
  background-size: contain;
  width: 100%;
  display: none;
}

.profile {
  padding: 20px 0;
  padding-bottom: 80px;
}

.profile-list {
  display: flex;
  align-items: center;
  padding-top: 200px;
  padding-left: 50px;
}

.profile-name {
  color: #fff;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.profile-number {
  color: #fff;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 12px;
}

.profile-item {
  margin-top: -55px;
  margin-left: 15px;
}

.App .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #232323 !important;
}

.App .css-1aquho2-MuiTabs-indicator {
  background-color: #232323 !important;
}

@media (max-width: 510px) {
  .profileBackground {
    display: none;
    height: auto;
  }

  .delete-list {
    width: 90% !important;
    margin: 0 auto;
  }
  .delete-submit {
    width: 100% !important;
  }

  .delete-name {
    font-size: 24px !important;
    line-height: 28px !important;
  }
  .delete-text {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .delete-img {
    width: 280px;
  }

  .announcement .container {
    padding: 0;
  }

  .announcement-card {
    width: 100%;
  }
  .profileBackground-mobile {
    display: block;
  }

  .profile-img {
    width: 80px;
    height: 80px;
  }
  .profile-list {
    padding-top: 130px;
  }

  .profile-name {
    margin-top: 20px;
    font-size: 17px;
    margin-bottom: 0;
  }

  .profile {
    padding-bottom: 40px;
  }

  .profile-number {
    font-size: 12px;
    padding-left: 15px;
  }

  .profile-item {
    margin-left: 0;
  }

  .addImage-box-item,
  .addImage-box-button {
    width: 45% !important;
    height: 150px !important;
  }
  .addImage-box-button > svg {
    width: 45px !important;
    height: 45px !important;
  }
}
