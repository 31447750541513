.setup-img > h3 {
  color: #1e1e1e;
  font-family: "Archivo", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 120%;
}

.setup-img-upload {
  display: flex;
  align-items: center;
  gap: 24px;
  cursor: pointer;
}

.setup-img-upload > span {
  color: #f26957;
  font-family: "Archivo", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.18px;
}

.setup-img-default {
  position: relative;
}

.setup-img-default > img,
.setup-img-upload > img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.setup-img-default > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
  color: gray;
}

.setup-img-upload > input {
  display: none;
}

.setup-btn-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.setup-logOut {
  margin-top: 48px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: none;
  color: #ff463a;
  font-family: "Archivo", sans-serif;
  font-size: 22px;
  font-weight: 400;
  background-color: transparent;
  cursor: pointer;
}

.setup-logOut > svg {
  width: 24px;
  height: 24px;
  color: #ff463a;
}

@media (max-width: 560px) {
  .product-create-form-button {
    padding: 16px 30px;
    font-size: 15px;
  }

  .setup-logOut {
    font-size: 15px;
    gap: 8px;
  }
}
