.support {
  padding: 50px 0;
}

.support-top {
  border-radius: 16px;
  background: #5bc4bd;
  padding: 92px 307px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.support-name {
  color: #000;
  font-family: Archivo, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px; /* 130% */
}

.support-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--foundation-grey-normal, #232323);
  font-family: Archivo, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.support-text {
  color: var(--foundation-grey-normal, #232323);
  font-family: Archivo, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}

@media (max-width: 700px) {
  .support-img {
    width: 300px;
    object-fit: contain;
  }
  .support-top {
    padding: 37px 120px;
  }
}

@media (max-width: 550px) {
  .support-img {
    width: 180px;
  }
  .support-top {
    padding: 37px 70px;
  }
  .navbar-support {
    width: 100px !important;
    font-size: 12px !important;
    height: 40px !important;
    margin-left: 15px !important;
  }
  .navbar-profiles {
    display: none !important;
  }
}
