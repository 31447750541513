.like {
  padding: 40px 0;
}

.like .product-name {
  color: #000;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  border-bottom: 3px solid #000;
  display: inline;
  text-transform: uppercase;
}
