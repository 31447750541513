.notfindComp {
  width: 100%;
  height: 70vh;
}

.not_find_flex {
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div_icon {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.not_find_result_text {
  color: #000;
  text-align: center;
  font-family: Archivo;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  opacity: 0.6;
  margin-top: 53px;
}
