.header {
  padding: 25px 0;
  padding-top: 0;
}

.header-bgimage {
  background-image: url("../../../../assets/newbanner.svg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 593px;
  border-radius: 32px;
  padding: 0 10px;
}

.header h1 {
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  padding-top: 74px;
  max-width: 600px;
  margin-left: 100px;
  text-transform: uppercase;
}

body
  .header
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 100px !important;
  color: var(--foundation-grey-normal, #232323) !important;
  font-family: "Archivo", sans-serif !important;
  background: var(--foundation-grey-light, #e9e9e9) !important;
}

body .header .css-fvipm8 {
  border-radius: 100px !important;
  color: var(--foundation-grey-normal, #232323) !important;
  font-family: "Archivo", sans-serif !important;
  font-weight: 500 !important;
  background: var(--foundation-grey-light, #e9e9e9) !important;
  margin: 0 5px !important;
}

.header-item {
  display: flex;
  align-items: center;
  border-radius: 1000px;
  background: #fff;
  max-width: 810px;
  padding: 5px 10px;
  margin-top: 42px;
  gap: 10px 15px;
  margin-left: 100px;
}

.header__item {
  display: flex;
  align-items: center;
  border-radius: 1000px;
  background: #fff;
  max-width: 810px;
  padding: 15px 10px;
  margin-top: 42px;
  gap: 10px 15px;
  margin-left: 100px;
}

.header__items {
  display: none;
}

.header label {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 100%;
}

.header input {
  border: none;
  outline: none;
  padding: 5px 12px;
  width: 100%;
  font-size: 18px;
}

.header input::placeholder {
  color: var(--foundation-grey-light-active, #bbb);
  font-family: "Archivo", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header-list {
  max-width: 1220px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
  overflow-x: scroll;
  margin-top: 69px;
  margin-left: 30px;
  position: relative;
}

.header-list::-webkit-scrollbar {
  width: 0px !important;
}

.header-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}

.header-list::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: transparent;
}

.header-link {
  color: #fff;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  width: 100% !important;
}

.header-link-span {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.33);
  backdrop-filter: blur(6px);
}

.header-link-span p svg path {
  fill: #fff;
}

.header-subname {
  margin-top: 5px;
  width: 105px !important;
  font-size: 16px;
}

@media (max-width: 780px) {
  .header input {
    padding: 12px 5px;
    width: 90%;
    font-size: 14px;
  }

  .header .css-1nrlq1o-MuiFormControl-root {
    display: none;
  }

  .header h1 {
    font-size: 55px;
    line-height: 70px;
  }

  .header-item {
    display: none;
  }
  .header__items {
    display: block;
    margin-left: 10px;
    width: 98%;
    padding: 8px 12px;
  }
}

@media (max-width: 680px) {
  .header-link {
    font-size: 18px;
  }

  .header-link-span {
    width: 50px;
    height: 50px;
  }

  .header-link-span svg {
    width: 20px;
    height: 20px;
  }

  .header-icons {
    width: 25px;
    height: 25px;
  }

  .header h1 {
    font-size: 40px;
    line-height: 60px;
    margin-left: 20px;
  }

  .header-item {
    margin-left: 20px;
  }

  .header .css-vmru51-MuiFormControl-root {
    display: none;
  }
  .header-list {
    margin-left: 20px;
  }
}

@media (max-width: 520px) {
  .header h1 {
    font-size: 38px;
    line-height: 60px;
  }
}

@media (max-width: 455px) {
  .header h1 {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    margin: 0 20px !important;
  }

  .header__items {
    margin-left: 4px;
  }

  .header .container {
    padding: 0 10px;
    margin-top: 25px;
  }

  .header-bgimage {
    min-height: 450px;
  }

  .header-item {
    margin-left: 0 !important;
  }
  .header-bgimage {
    padding: 0;
  }

  .header-list {
    margin-left: 0px !important;
    width: 100%;
    gap: 2px;
  }
  .header-link {
    height: 100px;
    padding: 0 !important;
    width: 23% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  body .header-link:nth-child(1) {
    margin-left: 240px !important;
  }

  .header-subname {
    font-size: 12px;
  }
}
