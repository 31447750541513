.navbar {
  position: relative;
  padding: 30px 0;
  padding-bottom: 0px;
}

.navbar-count {
  background-color: #f26957;
  color: #ffffff;
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 50%;
  position: absolute;
  top: 45%;
  left: 46%;
}

.navbar-counts {
  background-color: #f26957;
  color: #ffffff;
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 50%;
  position: absolute;
  top: 45%;
  left: 59%;
}

.navbar-support {
  display: flex;
  height: 50px;
  width: 150px;
  text-decoration: none;
  background: transparent;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--foundation-orange-normal, #f26957);
}

.navbar-support::after {
  content: "";
  display: flex;
  align-items: center;
  justify-self: center;
  width: 150px;
  height: 40px;
  position: absolute;
  background-color: var(--foundation-orange-normal, #f26957);
  transform: rotate(50deg);
  z-index: -10;
  animation: rotated 3s linear infinite;
}

.in_btn {
  background-color: #fff;
  width: 180px;
  height: 46px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  font-family: sans-serif;
}

@keyframes rotated {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.responsiveNavbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: #ffffff;
  display: none;
  z-index: 1003;
  box-shadow: 0 0 20px 0 #0000001e;
}

.fixedContainer {
  width: 100% !important;
  padding: 0;
}

.fixedNav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fixedNavIconBox h4 {
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin: 0;
}

.fixed-navbar-Ic {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.fixNavIconL {
  font-size: 25px;
  color: #000;
}

.plusIconFixNAv {
  font-size: 55px;
  color: #f26957;
}

.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-left {
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-right {
  max-width: 830px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px 20px;
}

.navbar-profiles {
  border-radius: 100px;
  background: var(--foundation-orange-light-active, #fbd1cb);
  display: inline-flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--foundation-orange-normal, #f26957);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s all ease-in-out;
  border: 2px solid var(--foundation-orange-light-active, #fbd1cb);
}

.navbar-profiles:hover {
  background-color: transparent;
  color: #f26957;
}

.navbar-link {
  display: flex;
  align-items: center;
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 15px 10px;
  cursor: pointer;
}

.navbar-submit {
  border-radius: 100px;
  background: var(--foundation-orange-light-active, #fbd1cb);
  display: inline-flex;
  padding: 10px 28px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--foundation-orange-normal, #f26957);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s;
  border: 2px solid #fbd1cb;
}

.navbar-submit:hover {
  color: #f26957;
  border: 2px solid #f26957;
  background-color: transparent;
}

.navbar-category {
  border-radius: 100px;
  background: var(--foundation-grey-light, #e9e9e9);
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  width: 200px;
  justify-content: center;
  cursor: pointer;
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 2px;
  margin-right: 2px;
}

@media (max-width: 1005px) {
  .navbar-link {
    font-size: 14px;
  }
}

@media (max-width: 840px) {
  .navbar-link {
    display: none;
  }
  .navbar-blog {
    display: none !important;
  }
  .modal-responsive-icon {
    display: block !important;
  }

  .navbar-link:first-child {
    display: flex;
  }

  .navbar-submit {
    display: none;
  }

  .navbar-category {
    display: none;
  }

  .navbar-right {
    width: 410px;
  }

  .responsiveNavbar {
    display: flex;
  }
}

@media (max-width: 450px) {
  .navbar .container {
    padding: 0 10px;
  }

  .navbar__icon {
    width: 95px;
  }
  .navbar-right {
    gap: 8px;
    display: flex;
    flex-direction: row-reverse;
  }
  .fixedNav {
    width: 100% !important;
  }
  .fixedNavIconBox {
    max-width: 25% !important;
    width: 25% !important;
  }
}
