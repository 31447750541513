.announcement-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  padding: 48px 0;
}

.announcement {
  width: 100%;
  box-sizing: border-box;
}

.announcement .container {
  padding: 0 !important;
}

.announcement-card {
  max-width: 415px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 10px;
  border-radius: 20px;
  background: #f1f1f1;
  position: relative;
  box-sizing: border-box;
  margin-top: 20px;
}

.profile-tab .css-19kzrtu {
  padding: 0;
}

.card__right_title {
  width: 210px;
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: -4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.card__medium {
  background: var(--foundation-orange-dark-active, #6d2f27) !important;
}

.card__new {
  background: #5bc4bd !important;
}

.card__right_subTitle {
  width: 177px;
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin-top: -4px;
}

.card__right_blok {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.blok__old {
  color: #fff;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #ff463a;
  text-decoration: none;
}

.blok__edit {
  border: none;
  display: flex;
  padding: 4px 9px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: #fbd1cb;
  color: var(--foundation-orange-dark, #b64f41);
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}

.blok__backet {
  display: flex;
  padding: 5px 11px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  background: #fbd1cb;
  cursor: pointer;
}

.card__left__check {
  width: 50px;
  height: 20px;
  background: #21df61;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2.5px;
  cursor: pointer;
}

.announcement-picture {
  width: 148px;
  height: 163px;
  border-radius: 10px !important;
  object-fit: cover;
}

.card__left__check:active {
  background: #c4c4c4;
}

.check__circle {
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
}

.check__circle:active {
  transform: translateX(-1.7rem);
  transition: 0.5s;
}

.card__cricles {
  position: absolute;
  right: 0;
  bottom: 0;
  border-bottom-right-radius: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  margin-top: 8px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderr {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderr:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderr {
  background-color: #21df61;
}

input:focus + .sliderr {
  box-shadow: 0 0 1px #21df61;
}

input:checked + .sliderr:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.sliderr.round {
  border-radius: 34px;
}

.sliderr.round:before {
  border-radius: 50%;
}

@media (max-width: 450px) {
  .announcement .container {
    padding: 0;
  }

  .announcement-picture {
    width: 110px;
    height: 100px;
  }
  .card__right_title {
    font-size: 15px;
    line-height: 18px;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    margin-bottom: 5px;
  }

  .card__right_blok {
    flex-wrap: wrap;
    gap: 5px 15px;
  }
}
