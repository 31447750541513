body .modal-login-body {
  background: #c96a30 !important;
  backdrop-filter: blur(9px) !important;
}

body .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent;
}

.navbar-profile {
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 15px;
}

.login-button {
  background: var(--foundation-grey-light-active, #bbb);
  width: 220px;
  height: 45px;
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border: none;
}

.login-button:nth-child(1) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.login-button:nth-child(2) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.login-list {
  margin-top: 60px;
  margin-left: 52px;
}

.modal-left {
  width: 50%;
}

.modal-page {
  display: flex;
  justify-content: space-between;
}

.modal-right {
  width: 50%;
}

.modal-img {
  width: 580px !important;
  height: 485px !important;
  margin-left: 35px;
}

@media (max-width: 1300px) {
  .css-1jdzi4o {
    width: 900px !important;
    height: 500px !important;
  }
  .modal-page .modal-img {
    width: 400px !important;
  }
  .login-list {
    margin-top: 15px !important;
    margin-left: 15px !important;
  }
  .login-button {
    width: 170px !important;
    font-size: 16px !important;
  }
  .register-form {
    padding: 0 15px !important;
  }
  .modal-page {
    align-items: center;
    margin-top: -30px !important;
  }
}

@media (max-width: 900px) {
  .css-1jdzi4o {
    height: 100vh !important;
    min-height: 100vh;
    width: 600px !important;
    padding: 15px 0 !important;
  }
  .modal-page .modal-img {
    width: 400px !important;
    height: 320px !important;
    object-fit: contain;
  }
  .modal-left,
  .modal-right {
    margin: 0 auto !important;
    width: 90% !important;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .modal-left {
    margin-top: 25px !important;
  }
  .modal-page {
    display: flex;
    margin-top: 0 !important;
    flex-direction: column-reverse;
  }
  .register-form {
    padding: 0 !important;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .register-form p {
    width: 380px !important;
  }
}

.modal-responsive-icon {
  display: none;
}

@media (max-width: 840px) {
  .navbar-profile {
    display: none;
  }
}

@media (max-width: 700px) {
  .css-1jdzi4o {
    height: 90vh !important;
    min-height: 90vh;
    width: 500px !important;
    padding: 15px 0 !important;
  }
  .modal-page .modal-img {
    width: 100% !important;
    height: 170px !important;
    object-fit: contain;
  }
}

@media (max-width: 500px) {
  .css-1jdzi4o {
    height: 80vh !important;
    min-height: 80vh;
    width: 370px !important;
    padding: 15px 0 !important;
  }
  .login-list {
    margin-top: -10px !important;
  }
  .register-input {
    width: 100% !important;
  }
  .register-form label {
    width: 100%;
  }
  .register-form {
    padding: 0 15px !important;
    width: 100%;
  }
  .form-button {
    width: 100% !important;
    padding: 0 20px !important;
    margin-top: 10px !important;
    height: 38px !important;
    font-size: 16px !important;
  }

  .register-form label {
    margin-top: -15px !important;
  }
  .aboutproduct-imgs {
    width: 80px !important;
    height: 80px !important;
  }
  .modal-left {
    width: 100% !important;
  }
  .modal-left div:nth-child(2) {
    width: 100% !important;
  }
  .register-form p {
    width: 100%;
    padding: 0 20px !important;
  }
  .register-label {
    width: 100%;
    margin-top: -12px;
  }
}
