.addImage-title {
  color: #1e1e1e;
  font-size: 26px;
  font-weight: 600;
  line-height: 120%;
  font-family: "Archivo", sans-serif;
}

.addImage-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.addImage-box-item {
  width: 250px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.addImage-box-button {
  width: 250px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 20px -3px rgba(181, 201, 219, 0.3);
  cursor: pointer;
}

.addImage-box-button > span {
  color: #313137;
  font-size: 16px;
  font-weight: 500;
  font-family: "Archivo", sans-serif;
  line-height: 120%;
  letter-spacing: 0.16px;
}

.addImage-box-button > svg {
  width: 64px;
  height: 64px;
  color: #bbbbbb;
}

.addImage-box-button > input {
  display: none;
}

.addImage-warning {
  margin-bottom: 10px;
  color: var(--foundation-orange-normal, #f26957);
  font-family: "Archivo", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.22px;
}

.addImage-warning-desc {
  color: #313137;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.product-create-label {
  display: block;
  width: 100%;
  max-width: 812px;
}

.product-create-label > h4 {
  margin-bottom: 12px;
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.PhoneInput > input {
  width: 100%;
  height: 50px;
  text-indent: 10px;
  border-radius: 12px;
  border: 2px solid var(--foundation-grey-light-hover, #dedede);
  background: #fff;
  outline: none;
  color: #000;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
}

.product-create-label input {
  width: 100%;
  height: 50px;
  text-indent: 10px;
  border-radius: 12px;
  border: 2px solid var(--foundation-grey-light-hover, #dedede);
  background: #fff;
  outline: none;
  color: #000;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
}

.product-create-label > textarea {
  padding: 10px 0;
  width: 100%;
  text-indent: 10px;
  border-radius: 12px;
  border: 2px solid var(--foundation-grey-light-hover, #dedede);
  background: #fff;
  outline: none;
  resize: none;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
}

.product-create-form-button {
  margin-top: 48px;
  display: flex;
  padding: 16px 52px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 40px;
  background: var(--foundation-orange-normal, #f26957);
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
}

.product-create-form .MuiFormControl-root {
  width: 100%;
  margin: 0 !important;
}

.product-create-form .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 12px !important;
  border: 2px solid var(--foundation-grey-light-hover, #dedede) !important;
}
