.blok {
  display: flex;
  gap: 50px;
  margin-top: 50px;
}

.blok__left_img {
  max-width: 516px;
  height: 540px;
  border-radius: 40px;
  /* border: 2px solid red; */
}

.blok__left {
  width: 40%;
}

.aboutproduct-img {
  /* max-width: 516px; */
  width: 100%;
  height: 540px;
  border-radius: 40px;
  /* border: 1px solid red; */
  object-fit: cover;
}

.blok__right_title {
  max-width: 584px;
  color: #000;
  font-family: "Archivo", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blok__right_subTitle {
  max-width: 558px;
  margin-top: -12px;
  color: #000;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.6000000238418579;
}

.blok__right_icons {
  max-width: 530px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icons__link {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #913f34;
  color: #fff;
  text-decoration: none;
}

.icons__oblast {
  display: flex;
  align-items: center;
  gap: 5px;
}

.icons__oblast_location {
  width: 24px;
  height: 24px;
}

.icons__oblast_subTitle {
  color: #232323;
  font-family: "Archivo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.blok__right_call {
  display: flex;
  gap: 60px;
  align-items: center;
  margin-top: 25px;
}

.call__link {
  display: inline-flex;
  padding: 10px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #f26957;
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.call__children {
  display: flex;
  align-items: center;
  gap: 8px;
}

.call__icon-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.orderer_title {
  color: #000;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2px;
}

.orderer_subTitle {
  color: #000;
  font-family: "Archivo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.6000000238418579;
}

.blok__right_note {
  max-width: 480px;
  margin-top: 30px;
  color: #222;
  font-family: "Archivo", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 12px;
  background: var(--foundation-orange-light-active, #fbd1cb);
}

.note__span {
  color: #f94135;
}

.slider {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-top: 32px;
}

.slider__left {
  cursor: pointer;
  border: 2px solid #a8a6a6;
  border-radius: 50%;
  padding: 6px;
}

.slider__right {
  cursor: pointer;
  background: #f26957;
  border-radius: 50%;
  padding: 7px;
}

.about-button {
  color: #000;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  background-color: transparent;
  border: none;
  margin: 0 auto;
  border-bottom: 2px solid #000;
}

.about {
  padding: 30px 0;
}

.about-top {
  text-align: center;
  border-bottom: 1.5px solid #e9e9e9;
}

.about-more {
  border-radius: 40px;
  background: var(--foundation-orange-normal, #f26957);
  color: #fff;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 240px;
  height: 48px;
  border: 2px solid #f26957;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.about-more:hover {
  background-color: transparent;
  color: #f26957;
}

.card__medium {
  background: var(--foundation-orange-dark-active, #6d2f27);
}

.card__new {
  background: #5bc4bd;
}

.aboutproduct-imgs {
  width: 96px;
  height: 96px;
  flex-shrink: 0;
  border-radius: 20px;
}

.mySwiper {
  width: 100%;
  margin-top: 15px;
  display: flex;
}

.mySwiper > .swiper-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.aboutproduct-slider {
  width: 35%;
  margin-right: auto;
  margin-left: 80px;
}

@media screen and (max-width: 1000px) {
  .blok__right_title {
    font-size: 35px;
  }

  .blok__right_subTitle {
    font-size: 15px;
  }
}

@media screen and (max-width: 840px) {
  .blok {
    flex-direction: column;
    align-items: center;
  }

  .blok__left {
    width: 100%;
  }

  .blok__left .mySwiper {
    max-width: 500px;
  }

  .mySwiper2 {
    width: 100%;
  }
  .delete-modal-product .css-9naxxq {
    width: 400px !important;
  }
}

@media screen and (max-width: 470px) {
  .call__link {
    font-size: 13px;
    display: flex;
    align-items: center;
    width: 100% !important;
  }
  .delete {
    width: 100%;
  }

  .product-modal-wrapper {
    width: 320px !important;
  }
  .product-modal-wrapper > h4 {
    margin-bottom: 10px;
    font-size: 24px !important;
    line-height: 36px !important;
  }
  .product-modal-wrapper > img {
    height: 140px !important;
  }
  .product-modal-wrapper > button {
    max-width: 200px;
    padding: 15px 10px;
  }
  .product-modal-wrapper > p {
    margin-top: 0;
  }
  .blok__right_icons {
    flex-wrap: wrap;
  }

  .aboutproduct-img {
    height: 400px;
    border-radius: 16px;
  }
  .call__icon-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .blok__right_call {
    gap: 5px;
    display: flex;
    align-items: baseline;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .delete-modal-product .css-9naxxq {
    width: 90% !important;
  }
}
