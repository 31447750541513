::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(130deg, #f26957 0%, #ffa00e 100%);
}

.loading-market {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 85px);
  width: 100%;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--foundation-grey-normal, #232323) !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #232323 !important ;
}

.PhoneInputCountry {
  display: none !important;
}

body .card__red {
  background-color: red !important;
}

body .card__active {
  background-color: #dd7a00 !important;
}

body .card__green {
  background-color: #01a601 !important;
}
