.like-img {
  width: 100%;
  min-width: 100%;
}

.likes .container {
  background-image: url("../../assets/icon/Rectangle\ 1.svg");
  background-repeat: no-repeat;
  min-height: 350px;
  margin-top: 30px;
}

.likes {
  margin-top: 50px;
}

.likes .likes__page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
}
.likes h2 {
  color: #fff;
  text-align: center;
  font-family: Archivo;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 125% */
  margin-top: 0;
}

@media (max-width: 1350px) {
  .likes {
    padding: 0 30px;
    border-radius: 30px;
  }
  .likes .container {
    border-radius: 30px;
  }
}

@media (max-width: 500px) {
  .likes h2 {
    font-size: 32px;
    line-height: 40px;
  }
  .likes {
    padding: 0 10px;
  }
  .likes .container {
    min-height: 180px;
    width: 100%;
    margin-top: 0;
    padding: 0 10px;
  }
  .likes .likes__page {
    padding-top: 40px;
  }
  .likes__page img {
    width: 48px;
  }
}
