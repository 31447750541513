.card {
  width: 315px;
  height: 352px;
  border-radius: 20px;
  background: #f1f1f1;
  position: relative;
  font-family: "Archivo", sans-serif;
  margin-top: 15px;
  overflow: hidden;
}

.card > img {
  border-radius: 15px;
}

.card__img {
  margin-bottom: 14px;
  margin-right: 12px;
  margin-left: 12px;
  width: 291px;
  height: 164px;
  object-fit: contain;
  border-radius: 15px !important;
}

.card__heart {
  padding: 7px;
  margin-right: 12px;
  background: #b2b6beff;
  border-radius: 50px;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 1001;
}

.card__title {
  width: 255px;
  padding-left: 12px;
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  margin-top: 0;
}

.card__subTitle {
  width: 190px;
  padding-top: 12px;
  padding-left: 12px;
  margin-bottom: 12px;
  color: var(--foundation-grey-normal, #232323);
  font-family: "Archivo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin-top: 0;
}

.card__link {
  margin-left: 12px;
  padding: 4px 16px;
  border-radius: 40px;
  background: #ff463a;
  color: #fff;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}

.card-heart {
  border: none;
  background-color: transparent;
  cursor: pointer;
  fill: rgba(0, 0, 0, 0.15);
  z-index: 1001;
}

.card__medium {
  background: var(--foundation-orange-dark-active, #6d2f27);
}

.card__new {
  background: #5bc4bd;
}

.card__cricle {
  position: absolute;
  right: -30px;
  bottom: -25px;
  transition: all 0.3s;
}

.showArrowIcon {
  right: 0;
  bottom: 0;
}

.card-photo {
  width: 291px;
  height: 164px;
  border-radius: 15px;
  margin-top: 10px;
  object-fit: cover;
}

@media (max-width: 450px) {
  .container {
    width: 100%;
  }
  .card {
    width: 48%;
    height: 300px;
    margin-top: 0;
    margin: 10px 0;
  }
  .products {
    gap: 10px;
    width: 100% !important;
  }
  .card-photo {
    width: 100%;
    margin-top: -6px;
    padding: 0 10px;
    height: 125px;
  }
  .card__title {
    width: 150px;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
  }
  .card__subTitle {
    width: 150px;
  }

  .products-all-home .container {
    padding: 0 8px;
  }
}
